export const environment = {
  production: true,
  title: 'Prod RHF Environment Heading',
  apiBaseUrl: 'https://rhfmobile.in/ec/reliance-rhf-web-0.1/api/rhf/v1',
  apiBaseForlogs:'https://rhfmobile.in/ec/reliance-rhf-web-0.1/api/rhf/v1',
  reCaptchaSiteKey: '6Leqyk0iAAAAADLKhh-QXAqGc_CW2TByVGAysxwv',
  mapAPIKeys:'AIzaSyCQXQk_ttamRDZD4byXfS76irf1j0-FaT0',
  adminApiBaseURL: 'https://10.63.253.130/ec/reliance-rhf-web-0.1/api/rhf/v1',
  adminInternal:['http://10.'],
  publicUrls : ['rhfmobile.in'],
  adminDashboardLabel:'Admin RHF'
};