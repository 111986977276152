import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { UserAPI } from 'src/app/shared/constants/api-endpoints/user-api.const';
import { AppConfig } from '../config';
import { CommunicationService } from './communication.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loader: string = '...Loading';
  private refreshTokenTimeout: any;
  constructor(
    private communicationService: CommunicationService,
    private router: Router,
    private toastr: ToastrService,
    private ngZone: NgZone
  ) {}

  authenticate(data: any): Observable<any> {
    return this.communicationService
      .post<any>(
        UserAPI.authenticateUser(),
        data,
        { platform: 'web' },
        this.loader
      )
      .pipe(
        tap((response: any) => {
          if (response) {
            let screenNames = response.screenNames;        
            localStorage.setItem(AppConfig.auth.token, response.token);
            localStorage.setItem(
              AppConfig.auth.refreshToken,
              response.refreshToken
            );
            localStorage.setItem(AppConfig.auth.sessionId, response.sessionId);
            localStorage.setItem(
              AppConfig.userRoutes,
              JSON.stringify(screenNames)
            );
            const helper = new JwtHelperService();
            const decodedToken = helper.decodeToken(response.token);
            localStorage.setItem('userId', decodedToken.sub);
            localStorage.setItem('userRole', JSON.stringify(decodedToken.role))
          }
        })
      );
  }

  isLoggedIn(): boolean {
    return this.getToken() !== null ? true : false;
  }

  getToken() {
    return localStorage.getItem('token');
  }
  getUserId() {
    return localStorage.getItem('userId');
  }
  getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  refreshToken(): Observable<any> {
    let refreshToken: any = this.getRefreshToken();
    let userId: any = this.getUserId();
    return this.communicationService
      .post<any>(
        UserAPI.refreshTokenUser(),
        { refreshToken: refreshToken, username: userId },
        { platform: 'web' }
      )
      .pipe(
        tap((response: any) => {
          if (response) {
            localStorage.setItem(AppConfig.auth.token, response.token);
            localStorage.setItem(
              AppConfig.auth.refreshToken,
              response.refreshToken
            );
            localStorage.setItem(AppConfig.auth.sessionId, response.sessionId);

            // this.startRefreshTokenTimer();
          }
        })
      );
  }

  //   private startRefreshTokenTimer() {
  //     // parse json object from base64 encoded jwt token
  //     let token : any = this.getToken();
  //     let refreshToken: any = this.getRefreshToken()
  //     const jwtToken: any =  jwt_decode(token);
  //     console.log(jwtToken);
  //     localStorage.setItem('userId', jwtToken.sub);
  //     localStorage.setItem(AppConfig.auth.roles, jwtToken.role)
  //     const expires = new Date(jwtToken.exp * 1000 );
  //     let newDate = new Date();
  //     // 300000
  //     let timeOut = (expires.getTime() - newDate.getTime()) - 300000;
  //     this.ngZone.runOutsideAngular(() => {
  //       // TODO document why this arrow function is empty
  //       this.refreshTokenTimeout = setTimeout(() => {
  //         this.refreshToken(refreshToken).subscribe();
  //       }, (timeOut));
  //     })

  // }

  // private stopRefreshTokenTimer() {
  //     clearTimeout(this.refreshTokenTimeout);
  // }

  // private stopRefreshTokenTimer() {
  //     clearTimeout(this.refreshTokenTimeout);
  // }

  logout(): void {
    this.communicationService
      .post(UserAPI.logout(), {})
      .subscribe((responseLogout: any) => {
        this.toastr.success(responseLogout.message);
      });
    this.clearLocalStorage();
  }

  clearLocalStorage() {
    localStorage.removeItem(AppConfig.auth.token);
    localStorage.removeItem(AppConfig.auth.refreshToken);
    localStorage.removeItem(AppConfig.auth.roles);
    localStorage.removeItem(AppConfig.auth.xsrfToken);
    localStorage.removeItem(AppConfig.auth.lan);
    localStorage.removeItem(AppConfig.userRoutes);
    localStorage.removeItem(AppConfig.auth.sessionId);
    localStorage.removeItem('userId');
    this.router.navigate(['/auth/login']);
  }

  getCurrentRoutes() {
    let userRoutes: any = localStorage.getItem('userRoutes');
    return JSON.parse(userRoutes);
  }
  getRoutesOnArray() {
    let userRoutes: [] = this.getCurrentRoutes();
    if (userRoutes?.length > 0) {
      return userRoutes.map((dataValue: any) => '/' + dataValue?.pathUrl);
    } else {
      return [];
    }
  }
}
