export const lovType = {
  allocate: 'ALLOCATE',
  reAllocate: 'REALLOCATE',
  actionMaster: 'ACTIONS',
  agencyMaster: 'AGENCYMASTER',
  agentMaster: 'AGENTMASTER',
  branchMaster: 'BRANCHMASTER',
  operationalBranchMaster: 'OPERATIONALBRANCHMASTER',
  userMaster: 'USERMASTERS',
  user:'USER',
  useropsbranchMaster:  'USEROPSBRANCHMASTER',
  searchloans: 'SEARCHLOAN',
  viewReceipt: 'VIEWRECEIPT',
  contactMaster:'CONTACTMODEMASTER',
  contactPlaceMaster : 'CONTACTPLACEMASTER',
  contactPersonMaster : 'CONTACTPERSONMASTER',
  userAudit:'USERAUDIT', 
  auditTrail:'AUDITTRAIL',
  report:'REPORT',
  summary :'SUMMARY',
  location : 'LOCATION',
};

export const apiUrls = {
  searchloans: 'Loan/Search',
  viewReceipts:'Receipts/View',
  viewReceiptDetails:'Receipts/Details',
  downloadReceipt:'Receipts/downloadReceipt',
  viewReceiptEmail:'Receipts/EMAIL',
  viewReceiptSms:'Receipts/SMS',
  viewreports:'Reports/View',
  summary:'Receipts/Summary',
  viewAudit:'Audit/Trail/View',
  downloadHandOff:'Receipts/DownloadHandOff',
  viewUserAudit:'User/Audit/View',
  lmsActionMaster: 'Loan/Orphan',
}
export const flagOptions = [
  { value: 'Y', label: 'Y' },
  { value: 'N', label: 'N' },
];
export const superRoleName = [
  { value: 'App User', label: 'App User' },
  { value: 'Admin', label: 'Admin' },
];

export const  paymentMode = [
  { value: 'Cash', label: 'Cash' },
  { value: 'DD', label: 'DD' },
  { value: 'Cheque', label: 'Cheque' },
  { value: 'RTGS/NEFT', label: 'RTGS/NEFT' },
];

// ['MFOS Admin','payment'];
export const userRole = ['payment'];

//[Note]:- this urls will have recaptch required field(captch is compulsory) for below mentions hostname.

export const publicUrls = ['rhfmobile.in']

//----------
