import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './core/guards/authentication.guard';
import { AuthInterceptor, AuthInterceptorProvider } from './core/interceptors/auth.interceptor';
import { ErrorInterceptorProvider } from './core/interceptors/error.interceptor';
import { FackBackendInterceptor } from './core/interceptors/fack-backend.interceptor';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('../app/feature/public/public.module').then(m => m.PublicModule),
  },
  {
    path: '',
    loadChildren: () => import('./feature/secure/secure.module').then(m => m.SecureModule),
  },
  // {
  //   path: '**',
  //   redirectTo: '/auth/login', 
  //   pathMatch: 'full'
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
  providers: [
    FackBackendInterceptor,
    AuthInterceptorProvider,
    // ErrorInterceptorProvider,
  ]
})
export class AppRoutingModule { }
