import { lovType, apiUrls } from './config';

export const allocateQuery = `/Loan/Detail?loan=${lovType.allocate}&pageNo=[pageNo]&pageSize=[pageSize][search]`;
export const reAllocateQuery = `/Loan/Detail?loan=${lovType.reAllocate}&pageNo=[pageNo]&pageSize=[pageSize][search]`;
export const actionMasterQuery = `/Lov/${lovType.actionMaster}?pageNo=[pageNo]&pageSize=[pageSize][search]`;
export const agencyMasterQuery = `/Lov/${lovType.agencyMaster}?pageNo=[pageNo]&pageSize=[pageSize][search]`;
export const agentMasterQuery = `/Lov/${lovType.agentMaster}?pageNo=[pageNo]&pageSize=[pageSize][search]`;
export const branchMasterQuery = `/Lov/${lovType.branchMaster}?pageNo=[pageNo]&pageSize=[pageSize][search]`;
export const operationalBranchMasterQuery = `/Lov/${lovType.operationalBranchMaster}?pageNo=[pageNo]&pageSize=[pageSize][search]`;
export const userMasterQuery = `?pageNo=[pageNo]&pageSize=[pageSize][search]`;
export const useropsbranchmasterQuery = `/Lov/${lovType.useropsbranchMaster}?pageNo=[pageNo]&pageSize=[pageSize][search]`
export const searchLoansQuery = `/${apiUrls.searchloans}?pageNo=[pageNo]&pageSize=[pageSize][search]`;
export const viewReceiptQuery = `/${apiUrls.viewReceipts}?pageNo=[pageNo]&pageSize=[pageSize][search]`;
export const contactmasterQuery = `/Lov/${lovType.contactMaster}?pageNo=[pageNo]&pageSize=[pageSize][search]`
export const contactPersonmasterQuery = `/Lov/${lovType.contactPersonMaster}?pageNo=[pageNo]&pageSize=[pageSize][search]`
export const contactPlacemasterQuery = `/Lov/${lovType.contactPlaceMaster}?pageNo=[pageNo]&pageSize=[pageSize][search]`
export const userAuditQuery = `/${apiUrls.viewUserAudit}?pageNo=[pageNo]&pageSize=[pageSize][search]`
export const reportsQuery=`/${apiUrls.viewreports}?pageNo=[pageNo]&pageSize=[pageSize][search]`
export const summaryQuery = `/${apiUrls.summary}?pageNo=[pageNo]&pageSize=[pageSize][search]`

export const auditTrailQuery=`/${apiUrls.viewAudit}?pageNo=[pageNo]&pageSize=[pageSize][search]`
export const lmsActionMasterQuery = `/${apiUrls.lmsActionMaster}?pageNo=[pageNo]&pageSize=[pageSize][search]`;