import { Component } from '@angular/core';
import { environment} from '../../../../environments/environment'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ecollecting_angular-app';
  constructor(){
    //console.log(window.location)
    // console.log(environment)

  }
 
}
