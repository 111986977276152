import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  Observable,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AppConfig } from '../config';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpStatus } from 'src/app/shared/enums';
import { ToastrService } from 'ngx-toastr';
import { AppLoaderService } from '../services/app-loader.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  origin: any = [];
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private appLoaderService: AppLoaderService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.appLoaderService.start();
    const unProtectedResources = ['Login', 'RefreshToken'];
    const isUnProtectedResource =
      unProtectedResources.filter((x: string) => {
        return request.url.includes(x);
      }).length > 0;
    let modifiedReq = isUnProtectedResource === true ? request : this.setHeader(request);
    //console.log('Before Modified Request', modifiedReq)
    this.origin.push(window.location.hostname);
    let originURL = window.location.origin;
   // console.log('originURL', originURL);
    let windowArr = [originURL]
    let adminIntUrl = (Object.values(environment.adminInternal));
    const checker = (value: any) =>adminIntUrl.some((element) =>value.includes(element));
    const chekerValue = windowArr.filter(checker);
   // console.log("===========chekerValue length=============", chekerValue, adminIntUrl,chekerValue.length);
    // let originSUb = originURL.match(/(http|https)?:\/\/(\d+\.){1}/) 
    // //console.log('originSUb',originSUb);
    // let adminint = (Object.values(environment.adminInternal));
    // let matchCase1 = originSUb ?adminint.filter((x: any) => x.match(originSUb![0])): [] ;
    // console.log("matching found??", adminint,  matchCase1.length)
    // if (matchCase1.length > 0 ) {
      if (chekerValue.length > 0 ) {
      let APIURL = environment.adminApiBaseURL;
      let dummyURL = modifiedReq.url.split('v1')[1];
      let mainURL = APIURL + dummyURL;
      const apiReq = request.clone({ url: `${mainURL}` });
     // console.log('apiReq1', apiReq)
      modifiedReq = isUnProtectedResource === true ? apiReq : this.setHeader(apiReq)
    }
   // console.log('After Modified Request', modifiedReq)
    return next.handle(modifiedReq).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          let xsrfToken: any = event.headers.get('X-XSRF-TOKEN');
          localStorage.setItem(AppConfig.auth.xsrfToken, xsrfToken);
          this.appLoaderService.stop();
        }
      }),
      catchError((error: HttpErrorResponse): Observable<HttpEvent<any>> => {
        this.appLoaderService.stop();
        let errorMsg = '';

        if (error.error instanceof ErrorEvent) {
         // console.log('This is client side error', error);
          errorMsg =
            error.error && error.error.error
              ? error.error.error
              : error.error.message
              ? error.error.error
                ? error.error.error
                : error.error.message
              : 'Something went wrong.';
        } else {
          if ([HttpStatus.status401].includes(error.status)) {
            errorMsg =
              error.error && error.error.error
                ? error.error.error
                : error.error.message
                ? error.error.error
                  ? error.error.error
                  : error.error.message
                : 'Something went wrong.';
            if (error && error.status === 401 && !isUnProtectedResource) {
              // Refresh only if token is expired
              const token: any = localStorage.getItem('token');
              const helper = new JwtHelperService();
              if (!helper.isTokenExpired(token)) {
                // console.log('Token Expired..!');
                this.toastr.warning('Session Timed Out! Please Login');
                this.authService.logout();
                // return;
              }

              if (this.refreshTokenInProgress) {
                // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                // which means the new token is ready and we can retry the request again
                return this.refreshTokenSubject.pipe(
                  filter((result) => result !== null),
                  take(1),
                  switchMap(() => next.handle(this.setHeader(request)))
                );
              } else {
                this.refreshTokenInProgress = true;
                // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                this.refreshTokenSubject.next(null);

                return this.refreshAccessToken().pipe(
                  switchMap((success: boolean) => {
                    this.refreshTokenSubject.next(success);
                    return next.handle(this.setHeader(request));
                    // return ;
                  }),
                  // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                  // for the next time the token needs to be refreshed
                  finalize(() => (this.refreshTokenInProgress = false))
                );
              }
            }
            if (error.status === 401 && !error.url!.includes('Login')) {
              this.authService.clearLocalStorage();
            }
          } else if (
            [HttpStatus.status403, HttpStatus.status406].includes(error.status)
          ) {
            // server error from the API
            // console.log('This is server side 1st block', error);
            // debugger;
            errorMsg =
              error.error && error.error.error
                ? error.error.error
                : error.error.message
                ? error.error.error
                  ? error.error.error
                  : error.error.message
                : 'Something went wrong.';

            this.authService.clearLocalStorage();
          } else if ([HttpStatus.status404].includes(error.status)) {
            // API not found error
            errorMsg =
              error.error && error.error.error
                ? error.error.error
                : error.error.message
                ? error.error.error
                  ? error.error.error
                  : error.error.message
                : 'Something went wrong.';
            // return throwError(errorMsg);
          } else if ([HttpStatus.status500].includes(error.status)) {
            errorMsg =
              error.error && error.error.error
                ? error.error.error
                : error.error.message
                ? error.error.error
                  ? error.error.error
                  : error.error.message
                : 'Something went wrong.';
          } else {
            // server if not match any status
           // console.log('This is server side 2nd block', error.error);
            errorMsg = error.error && error.error.error ? error.error.error
            : error.error.message
            ? error.error.error
              ? error.error.error
              : error.error.message
            : 'Something went wrong.';
          }
        }
        this.appLoaderService.stop();
        if (errorMsg) this.toastr.error(errorMsg);
        return throwError(errorMsg);
      })
    );
  }

  private refreshAccessToken(): Observable<any> {
    return this.authService.refreshToken();
  }

  /* Private Methods */
  private setHeader(req: HttpRequest<any>): HttpRequest<any> {
    let modifiedReq: HttpRequest<any> = req;
    const token = localStorage.getItem(AppConfig.auth.token);
    const xsrfToken: any = localStorage.getItem(AppConfig.auth.xsrfToken);
    const sessionId: any = localStorage.getItem(AppConfig.auth.sessionId);

    if (!token && !xsrfToken && !sessionId) {
      this.authService.logout();
    }

    switch (req.method) {
      case 'GET':
      case 'DELETE':
      case 'PUT':
      case 'POST':
      case 'PATCH':
        if (modifiedReq.url.indexOf('downloadCSV') > -1) {
          modifiedReq = req.clone({
            headers: new HttpHeaders({
              // Cookie: `XSRF-TOKEN=${xsrfToken}`,
              SESSIONID: sessionId,
              Authorization: `Bearer ${token}`,
              'X-XSRF-TOKEN': xsrfToken,
              //'X-Auth-Token': sessionId,
              platform: 'web',
              appVersion:'0'
              //'Cookie': `XSRF-TOKEN=${xsrfToken}`
            }),
            withCredentials: true,
            responseType: 'text',
          });
        } else if (
          modifiedReq.url.indexOf('downloadReceipt') > -1 ||
          modifiedReq.url.indexOf('DownloadHandOff') > -1
        ) {
          modifiedReq = req.clone({
            headers: new HttpHeaders({
              // Cookie: `XSRF-TOKEN=${xsrfToken}`,
              SESSIONID: sessionId,
              Authorization: `Bearer ${token}`,
              'X-XSRF-TOKEN': xsrfToken,
              //'X-Auth-Token': sessionId,
              platform: 'web',
              appVersion:'0'
              //'Cookie': `XSRF-TOKEN=${xsrfToken}`
            }),
            withCredentials: true,
            responseType: 'blob',
          });
        } else {
          modifiedReq = req.clone({
            headers: new HttpHeaders({
              // Cookie: `XSRF-TOKEN=${xsrfToken}`,
              SESSIONID: sessionId,
              Authorization: `Bearer ${token}`,
              'X-XSRF-TOKEN': xsrfToken,
              //'X-Auth-Token': sessionId,
              platform: 'web',
              appVersion:'0'
              //'Cookie': `XSRF-TOKEN=${xsrfToken}`
            }),
            withCredentials: true,
            // responseType: 'text'
          });
        }

        break;
    }
    // console.log(modifiedReq);
    return modifiedReq;
  }
}

export const AuthInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};
