import { Injectable } from "@angular/core";

@Injectable()
export class ValidationMsgService {

    private errorMessages: any = {
        'userName-required-msg' : "Username is a required field",
        'userName-minlength-msg' : "Username must have minimum 8 characters",
        'userName-maxlength-msg' : "Username can have maximum 30 characters",

        'userId-minlength-msg' : "User Id must have minimum 8 digit",
        'userId-maxlength-msg' : "User Id can have maximum 10 digit",
        'userId-pattern-msg' : "User Id should be numeric only",

        'password-required-msg' : "Password is a required field",
        'password-minlength-msg' : "Password must have minimum 8 digit",
        'password-pattern-msg' : "Password should be numeric only",
        'password-maxlength-msg' : "Password can have maximum 10 digit",

        'confirmPassword-required-msg' : "Password is a required field",
        'firstName-required-msg' : "First Name is a required field",
        'lastName-required-msg' : "Last Name is a required field",
        'userId-required-msg' : "User Id is a required field",
        'username-required-msg' : "Username is a required field",
        'username-pattern-msg' : "Username should be numeric only",
        'username-minlength-msg' : "Username must have minimum 8 digit",
        'username-maxlength-msg' : "Username can have maximum 10 digit",
        'role-required-msg' : "Role is a required field",
        'roleName-required-msg' : "Role name is a required field",
        'actionName-required-msg': 'Action Name is required field',
        'actionCode-required-msg': 'Action Code is required field',
        'actionCode-minlength-msg': 'Action Code must have minimum 8 digit',
        'actionDescription-required-msg': 'Action Description is required field',
        'branchName-required-msg': 'Branch Name is required field',
        'branchCode-required-msg': 'Branch Code is required field',
        'code-required-msg': 'Code is required field',
        'description-required-msg': 'Description is required field',
       
        'agencyid-required-msg': 'Agency Id is required field',
        'agencyname-required-msg': 'Agency Name is required field',        
        'agencyph-required-msg': 'Agency PH is required field',
        'agencycity-required-msg': 'Agency City is required field',
        'agencyaddress-required-msg': 'Agency Address is required field',

        'agentCode-required-msg': 'Agent Code is required field', 
        'agentName-required-msg': 'Agent Name is required field',
       
        //branchCode-required-msg': 'branchCodeis required field',
        'branchId-required-msg': 'Branch Id is required field',        
        'branchDescription-required-msg': 'Description is required field',
        'status-required-msg': 'Status is required field',
        'mapCode-required-msg': 'Map Code is required field',
        
        'email-required-msg': 'Email is required field',
        'email-email-msg': 'Email is not in valid format',
        'alternativeEmail-email-msg': 'Email is not in valid format',
        'mobile-required-msg': 'Mobile Number is  required field',
        'mobile-maxlength-msg': 'Mobile Number should not more than 10 digit',
        'mobile-minlength-msg' : "Mobile Number must have minimum 10 digit",
        'alternativeMobile-maxlength-msg': 'Mobile Number should not more than 10 digit',
        'alternativeMobile-minlength-msg' : "Mobile Number must have minimum 10 digit",
        'utrNumber-required-msg': 'Utr number is  required field',
        'utrNumber-maxlength-msg' : "Utr number should not more than 35 digit.",
        'ddOrChequeNumber-required-msg': 'DD/Cheque number is  required field',
        'ddOrChequeDate-required-msg': 'DD/Cheque date is  required field',
        'confirmDdOrChequeNumber-required-msg': 'DD/Cheque number is  required field',
        'bankName-required-msg': 'Bank name is  required field',
        'bankBranch-required-msg': 'Bank branch is  required field',
        'micrCode-required-msg': 'MICRCODE is  required field',
        'micrCode-minlength-msg' : "MICRCODE must have minimum 9 digit",
        'micrCode-maxlength-msg' : "MICRCODE should not more than 10 digit.",
        'receiptNumber-required-msg': 'Receipt Number is  required field',
        'receiptDate-required-msg': 'Receipt Date is  required field',
        'paymentMode-required-msg': 'Please select payment mode',
        'contactPlace-required-msg': 'Please select contact place',
        'chooseMode-required-msg': 'Please select choose mode',
        'contactPerson-required-msg': 'Please select Contact Person',
        // 'email-email-msg': 'Email is not in valid format',
        'adharNumber-minlength-msg' : "Aadhar Number must have minimum 12 digit",
        'adharNumber-maxlength-msg' : "Aadhar Number should not more than 12 digit.",
        'confirmAdharNumber-notEqual-msg' : "Aadhar Number do not match.",
        'confirmDdOrChequeNumber-notEqual-msg' : "DDorCheque Number do not match.",
        'panNumber-required-msg': 'PAN Number is  required field',
        'panNumber-pattern-msg' : "PAN Number is Invalid",
        'receiptDate-compareReceiptDatewithLmsDate-msg' : "Receipt date should not be lesser than LMS Date and greater than today's date",
        'receiptDate-selectedDate-msg' : "Receipt date should be equal to Today's Date",

    }
    
    public getValidationMsg(validationId:string):string {
        // console.log(this.errorMessages[validationId]);
        return this.errorMessages[validationId];
    }
    


}