import { environment } from "src/environments/environment";

export const UserAPI = {
    authenticateUser(): string {
        return `${environment.apiBaseUrl}/Login`;
    },
    logout(): string {
        return `${environment.apiBaseUrl}/Logout`;
    },

    refreshTokenUser(): string {
        return  `${environment.apiBaseUrl}/RefreshToken`;
    },

    getUser(): string {
        return  `${environment.apiBaseUrl}/Users`;
    },
    getUserMasters(): string {
      return `${environment.apiBaseUrl}/UserMasters`;
    },
    createUser(): string {
        return  `${environment.apiBaseUrl}/Users`;
    },
    activateUser(): string {
        return  `${environment.apiBaseUrl}/Users/Activation`;
    },
    resetPassword(): string {
        return  `${environment.apiBaseUrl}/ResetPassword`;
    },
    endActiveSession(): string {
        return  `${environment.apiBaseUrl}/EndActiveSession`;
    },
    userProfile(): string {
        return  `${environment.apiBaseUrl}/Profile`;
    },
    userMapping(): string {
        return `${environment.apiBaseUrl}/UserMapping`;
    },
    importUser():string{
        return `${environment.apiBaseUrl}/UploadCsv`;
    }
}