export const AppConfig =
{
    auth:
    {
        token: 'token',
        refreshToken: 'refreshToken',
        roles: 'roles',
        xsrfToken: 'xsrf-token',
        sessionId: 'sessionId',
        lan:'lan',
        idleTimeoutInMinutes: 1
    },
    receiptNumber:'receiptNumber',
    userRoutes:'userRoutes',
    currencyFormat: 'INR',
    dateFormat: 'dd-MMM-yyyy',
    timeFormat: 'hh:mm a',
    enableConsoleLog: {
        error: true,
        message: false
    },
    apiCaching: {
        enable: true,
        age: 3000
    },
    loanPaymentDetails: 'loanPaymentDetails',
    paymingEmiAmount: 'paymingEmiAmount',
    pageName: 'pageName'
};